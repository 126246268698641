import { useState } from "react";
import poop from "../Assets/PSL_3_Poop.png";
import hoveredPoop from "../Assets/Poop Icon_Blue.png";
import singaporeFlag from "../Assets/singapore-flag.png";
import malaysiaFlag from "../Assets/malaysia_flag.png";

const PoopDonor = () => {
  const [hover, setHover] = useState({
    singapore: false,
    malaysia: false,
  });
  return (
    <div className="seven-page" id="poopdonor">
      <p className="head">
        Sign up to be a <span>gut microbiome donor</span> with{" "}
        <span>AMILI!</span> Every registration counts.
      </p>
      <p className="small">
        For interested poop donors based in Singapore, please sign-up here:{" "}
      </p>
      <a
        href="https://amili.syd1.qualtrics.com/jfe/form/SV_1BKtrbVutEHcu5E"
        target="_blank"
        onMouseEnter={() => setHover({ ...hover, singapore: true })}
        onMouseLeave={() => setHover({ ...hover, singapore: false })}
      >
        <img
          src={hover.singapore ? hoveredPoop : poop}
          style={{ padding: hover.singapore ? "8px" : "0" }}
          className="poopImg"
          alt="poop"
        />{" "}
        Be a Poop donor
        <img src={singaporeFlag} className="flagImg" alt="malaysia flag" />
      </a>
      <p className="small">
        For interested poop donors based in Malaysia, please sign-up here:{" "}
      </p>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLScLlUJb0IPQfTVe4x8XdlM0818lAt8ClF6NmNeik5qf3td_fw/viewform"
        target="_blank"
        onMouseEnter={() => setHover({ ...hover, malaysia: true })}
        onMouseLeave={() => setHover({ ...hover, malaysia: false })}
      >
        <img
          src={hover.malaysia ? hoveredPoop : poop}
          style={{ padding: hover.malaysia ? "8px" : "0" }}
          className="poopImg"
          alt="poop"
        />{" "}
        Be a Poop donor
        <img src={malaysiaFlag} className="flagImg" alt="malaysia flag" />
      </a>
    </div>
  );
};

export default PoopDonor;
